<template>
  <!-- 预警策略 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('warning_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="500"
      @on-visible-change="modalShow"
    >
      <Form :label-width="120" ref="form" :model="form" :rules="rules">
        <FormItem label="选择传感器" prop="sensorName">
          <Input
            readonly
            placeholder="请选择传感器"
            style="width: 300px"
            v-model="form.sensorName"
            @on-focus="
              () => {
                addSensorShow = true;
              }
            "
          ></Input>
        </FormItem>
        <FormItem label="范围名称" prop="name">
          <Input
            clearable
            style="width: 300px"
            placeholder="请输入范围名称"
            v-model="form.name"
          ></Input>
        </FormItem>
        <FormItem label="传感器范围" prop="categoryMinRange">
          <div style="display: flex">
            <InputNumber
              v-model="form.categoryMinRange"
              :step="0.1"
              style="width: 145px"
            ></InputNumber>
            ~
            <InputNumber
              v-model="form.categoryMaxRange"
              :step="0.1"
              style="width: 145px"
            ></InputNumber>
          </div>
        </FormItem>
        <Row>
          <Col span="7">
            <FormItem label="颜色">
              <ColorPicker v-model="form.eventColor" />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="等级">
              <Input
                clearable
                style="width: 60px"
                v-model="form.leavel"
              ></Input> </FormItem
          ></Col>
          <Col span="5">
            <FormItem label="预警提醒">
              <Checkbox
                :true-value="1"
                :false-value="0"
                v-model="form.isWarning"
              ></Checkbox>
            </FormItem>
          </Col>
        </Row>
        <FormItem label="备注">
          <Input
            clearable
            style="width: 300px"
            type="textarea"
            v-model="form.remark"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <add-sensor
      v-model="addSensorShow"
      @on-change="getSensor"
      :sensorGuid="sensorGuid"
    ></add-sensor>
  </div>
</template>

<script>
import addSensor from "./addSensor";
export default {
  name: "",
  components: {
    addSensor,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "范围名称",
            minWidth: 200,
            align: "center",
            key: "name",
          },
          {
            title: "传感器",
            align: "center",
            key: "sensorName",
          },
          {
            title: "范围最小值",
            align: "center",
            key: "categoryMinRange",
          },
          {
            title: "范围最大值",
            align: "center",
            key: "categoryMaxRange",
          },
          {
            title: "颜色",
            render: (h, { row }) => {
              if (!row.eventColor) return <span>--</span>;
              return (
                <div
                  style={{
                    width: "30px",
                    height: "20px",
                    borderRadius: "4px",
                    backgroundColor: row.eventColor,
                  }}
                ></div>
              );
            },
          },
          {
            title: "等级",
            align: "center",
            key: "leavel",
          },
          {
            title: "预警提醒",
            align: "center",
            render: (h, { row }) => {
              return <span>{row.isWarning == 1 ? "预警" : "正常"}</span>;
            },
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("warning_update") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("warning_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增范围",
            ca: "warning_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "范围名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "传感器",
              component: "input",
              field: "sensorName",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        guid: "",
        sensorType: "",
        sensorCategory: "",
        categoryMinRange: 0,
        categoryMaxRange: 0,
        name: "",
        eventColor: "#06BAA1",
        leavel: "",
        isWarning: 0,
        sensorName: "",
      },
      rules: {
        sensorName: [{ required: true, message: "请选择传感器" }],
        name: [{ required: true, message: "请填写范围名称" }],
        categoryMinRange: [
          { required: true, message: "请填写传感器范围" },
          {
            validator: (rule, value, callback) => {
              if (this.form.categoryMaxRange <= value) {
                callback("最大最小值范围错误");
                return;
              }
              callback();
            },
          },
        ],
      },
      search_data: {},
      //添加传感器窗口
      addSensorShow: false,
      sensorGuid: "",
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.sensorGuid = "";
      this.modal = {
        show: true,
        title: "新增传感器范围",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      if (!this.CA("warning_check")) return;
      this.table.loading = true;
      this.$post(this.$api.WARNING_MANAGE.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getSensor(val) {
      this.form.sensorName = val.name;
      this.form.sensorType = val.sensorType;
      this.form.guid = val.guid;
      this.form.sensorCategory = val.sensorCategory;
      this.addSensorShow = false;
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.sensorGuid = row.guid;
      this.modal = {
        show: true,
        title: "编辑传感器范围",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.WARNING_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      let params = this.form;
      let url;
      if (params.id) {
        url = this.$api.WARNING_MANAGE.UPDATE;
      } else {
        url = this.$api.WARNING_MANAGE.ADD;
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        guid: "",
        sensorType: "",
        sensorCategory: "",
        categoryMinRange: 0,
        categoryMaxRange: 0,
        name: "",
        eventColor: "#06BAA1",
        leavel: "",
        isWarning: 0,
        sensorName: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>