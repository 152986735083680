<template>
  <Modal
    :value="value"
    title="传感器列表"
    @input="(val) => $emit('input', val)"
    @on-visible-change="modalShow"
    :width="800"
  >
    <Table
      :data="table.data"
      :loading="table.loading"
      :columns="table.columns"
      @on-row-click="chooseSensor"
    ></Table>
    <p slot="footer">
      <Button @click="() => $emit('input', false)">取消</Button>
      <Button type="primary" @click="submit">提交</Button>
    </p>
  </Modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sensorGuid: {
      type: [Number, String],
      default: 0,
    },
  },
  name: "",
  data() {
    return {
      table: {
        loading: false,
        data: [],
        columns: [
          {
            title: " ",
            width: 60,
            render: (h, { row }) => {
              return (
                <div>
                  {row.guid == this.sensorIndex && (
                    <a class="iconfont icon-duoxuan"></a>
                  )}
                  {row.guid !== this.sensorIndex && (
                    <div style="cursor:pointer; width: 14px;height: 14px;border: 1px solid #ececec; border-radios:3px"></div>
                  )}
                </div>
              );
            },
          },
          {
            title: "监测点名称",
            key: "groupName",
          },
          {
            title: "监测名称",
            key: "name",
          },
          {
            title: "通道类型",
            key: "channelTypeName",
          },
          {
            title: "场景类型/监测类别",
            width: 160,
            render: (h, { row }) => {
              return (
                <span>{row.sensorTypeName + "/" + row.sensorCategoryName}</span>
              );
            },
          },
          {
            title: "罗拉设备",
            key: "unicomName",
          },
        ],
      },
      sensorIndex: 0,
      sensorInfo: [],
    };
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.$post(this.$api.BUSSDEVICESENSOR.LIST)
        .then((res) => {
          this.table.data = res.list;
          if (this.sensorGuid) {
            res.list.forEach((item) => {
              if (this.sensorGuid == item.guid) {
                this.sensorInfo = item;
              }
            });
          }
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    chooseSensor(row) {
      this.sensorIndex = row.guid;
      this.sensorInfo = row;
    },
    submit() {
      this.$emit("on-change", this.sensorInfo);
    },
    modalShow(visible) {
      if (visible) {
        this.sensorIndex = this.sensorGuid;
        this.getList();
      }
    },
  },
  mounted() {
    // this.getList();
  },
};
</script>

<style lang="less" scoped>
</style>